import React, { Fragment } from "react"
import BoutonRadio, { BoutonRadioProps } from "../BoutonRadio/BoutonRadio"

export type ChampsBoutonsRadioProps = {
	legende: string,
	boutonsRadio: BoutonRadioProps[],
	valeurCochee?: string,
	labelEstTitreDeSection?: boolean
	disabled?: boolean
}

const ChampBoutonsRadios: React.FunctionComponent<ChampsBoutonsRadioProps> = ({ legende, boutonsRadio, valeurCochee = "",  labelEstTitreDeSection = true, disabled = false }: ChampsBoutonsRadioProps) => {
	return (
		<div className="fr-form-group">
			<fieldset
				className={ "fr-fieldset fr-fieldset--inline " }
			>
				<legend className="fr-fieldset__legend">
					{ labelEstTitreDeSection &&
					<h3 className="fr-text--lg fr-mb-0 fr-mt-2w">{ legende }</h3>
					}
					{ !labelEstTitreDeSection &&
					<Fragment>{ legende }</Fragment>
					}
				</legend>
				{ boutonsRadio.map(boutonRadio => {
					return <BoutonRadio
						checked={ valeurCochee === boutonRadio.valeur }
						disabled={ disabled }
						id={ boutonRadio.id }
						idMessageErreur={ boutonRadio.idMessageErreur }
						key={ boutonRadio.id }
						label={ boutonRadio.label }
						messageChangementDeContexte={ boutonRadio.messageChangementDeContexte }
						nom={ boutonRadio.nom }
						onChange={ boutonRadio.onChange }
						valeur={ boutonRadio.valeur }
					/>
				})  }
			</fieldset>
		</div>
	)
}

export default ChampBoutonsRadios
